import { mapGetters } from 'vuex';
import { User } from '@/services/Api/index';
import {
  ROSETTE_ITEMS,
  ROSETTE_SUCCESSIBLE_TYPE,
} from '@/modules/rosettes/constants/rosetteConstants';

export default {
  data() {
    return {
      rosetteRewardPoint: 0,
      rosetteRewardText: '',
    };
  },
  computed: {
    ...mapGetters('app', ['getRosettes', 'getRosetteRewardStatus']),
    getRosetteVisibility() {
      return this.getRosettes.rosetteVisibility;
    },
    getRosettesInfo() {
      return this.getRosettes.rosetteInfo;
    },
    isVisibleSurpriseAwardBox() {
      return this.getRosetteRewardStatus;
    },
  },
  methods: {
    async setProgressValue() {
      this.getRosettesInfo?.forEach((rosette, index) => {
        const element = document.getElementById(`progress-bar-${index}`);
        const { isSuccessible, rosetteReferenceCount, rosetteCount } = rosette;
        if (!element) return;
        element.style.background =
          isSuccessible === 3
            ? '#22BB33'
            : isSuccessible === 2
            ? '#c60000'
            : `conic-gradient(#22BB33 ${(100 / rosetteReferenceCount) * rosetteCount * 3.6 +
                'deg'}, #ededed 0deg)`;
      });
    },
    getRosettesList() {
      User.getRosetteInfo().then(res => {
        if (!res?.data?.Data) return;
        this.$store.dispatch('app/setRosettes', res.data.Data);
      });
    },
    getRosetteRewardStatu() {
      User.getRosetteRewardStatu().then(res => {
        if (!res?.data?.Data) return;
        this.$store.dispatch('app/setRosetteRewardStatu', res.data.Data.status);
      });
    },
    setRosetteRewardPoint() {
      User.setRosetteRewardPoint()
        .then(res => {
          if (!res?.data?.Data) return;
          this.rosetteRewardPoint = res?.data?.Data?.point;
          this.rosetteRewardText = res?.data?.Data?.rewardText;
        })
        .catch(() => {
          const errorModalConfig = {
            isModalOpened: true,
            modalText: 'Tekrar Deneyiniz',
            modalType: 'uploadAlert',
            alertType: 'error',
            firstButtonText: 'KAPAT',
          };
          this.$store.dispatch('app/setModalConfig', errorModalConfig);
        });
    },
    checkNpsRosette(getNpsRosetteDetail) {
      const npsRosette = this.getRosettesInfo?.find(
        x =>
          x.rosetTypeId === ROSETTE_ITEMS.NpsSurvey.id &&
          x.isSuccessible === ROSETTE_SUCCESSIBLE_TYPE.Succesible,
      );
      if (this.getRosetteVisibility && npsRosette) {
        getNpsRosetteDetail();
      }
    },
  },
};
